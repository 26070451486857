interface Window {
    dataLayer: Record<string, any>[];
    conversionTrigger: ConversionTrigger;
}

/**
 * Class responsible for handling conversion events, such as clicks on elements
 * with a `data-conversion-trigger` attribute and form submissions.
 */
class ConversionTrigger {

    /**
     * Constructor that initializes event listeners for conversion triggers.
     */
    constructor() {
        this.initializeListeners();
    }

    /**
     * Initializes click event listeners for all elements with the `data-conversion-trigger` attribute.
     * @private
     */
    private initializeListeners() {
        const triggers = document.querySelectorAll('[data-conversion-trigger]');

        triggers.forEach(trigger => {
            trigger.addEventListener('click', (event) => this.handleClick(event));
        });
    }

    /**
     * Handles the click event on elements with the `data-conversion-trigger` attribute.
     * Extracts the event name from the element's attribute and sends the event.
     *
     * @param {Event} event - The click event object.
     * @private
     */
    private handleClick(event: Event) {
        const element = event.target as HTMLElement;
        const eventName = element?.getAttribute('data-conversion-trigger');

        this.sendEvent(eventName);
    }

    /**
     * Handles the submission of a form and sends a conversion event with additional variables.
     * Specifically extracts the email value from the form's email input field.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {string} eventName - The name of the conversion event to be sent.
     */
    public handleFormSend(form: HTMLFormElement, eventName: string) {
        const emailField = form.querySelector('input[type=email]') as HTMLInputElement;

        this.sendEvent(eventName, {'email': emailField?.value});
    }

    /**
     * Sends a conversion event to the `dataLayer` with optional additional variables.
     *
     * @param {string | null} eventName - The name of the conversion event. If null, no event is sent.
     * @param {object} [variables={}] - Additional key-value pairs to include in the event data.
     * @private
     */
    private sendEvent(eventName: string | null, variables: object = {}) {
        if (!eventName) return;

        const data = {...{'event': eventName}, ...variables};

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }
}

/**
 * Initializes the ConversionTrigger class when the DOM content is fully loaded.
 */
document.addEventListener('DOMContentLoaded', () => {
    window.conversionTrigger = new ConversionTrigger();
});